import { AlertMessage, Button } from 'cdh-data-portal-theme'
import { FallbackProps } from 'react-error-boundary'

import { DQErrorBoundaryDiv, ErrorBoundaryDiv, ErrorBoundaryInTileDiv } from './ErrorBoundaryFallbacks.styled'

export interface GenericFallbackProps extends FallbackProps {
  locationString: string
}

export const DQErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <DQErrorBoundaryDiv role='alert'>
      <AlertMessage
        title={'Something went wrong:'}
        message={error.message}
        endAdornment={<Button title={'Retry'} variant={'textDark'} onClick={resetErrorBoundary} />}
      />
    </DQErrorBoundaryDiv>
  )
}

export const ErrorFallback: React.FC<GenericFallbackProps> = ({ error, resetErrorBoundary, locationString }) => {
  return (
    <ErrorBoundaryDiv role='alert'>
      <AlertMessage
        title={`Something went wrong with the ${locationString}`}
        message={error.message}
        endAdornment={<Button title={'Retry'} variant={'textDark'} onClick={resetErrorBoundary} />}
      />
    </ErrorBoundaryDiv>
  )
}

export const ErrorInTileFallback: React.FC<GenericFallbackProps> = ({ error, resetErrorBoundary, locationString }) => {
  return (
    <ErrorBoundaryInTileDiv role='alert'>
      <AlertMessage
        title={`Something went wrong with the ${locationString}`}
        message={error.message}
        endAdornment={<Button title={'Retry'} variant={'textDark'} onClick={resetErrorBoundary} />}
      />
    </ErrorBoundaryInTileDiv>
  )
}
