import styled from '@emotion/styled'
import { MenuItem, Select, Typography } from 'cdh-data-portal-theme'
import { map } from 'lodash'
import React, { useState } from 'react'
import * as ReactDOM from 'react-dom'
import { hot } from 'react-hot-loader/root'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import App from './DQ'
import { ConfigurationContext, buildConfig, config as defaultConfig, environments } from './config'

const StyledSAContainer = styled.div`
  background: #ffffff;
  height: 100%;
`

const ContainerTwo = styled.div`
  margin: 0% 10em 0% 10em;
  background: #ffffff;
`

const ContainerThree = styled.div`
  margin: 0 auto;
  display: grid;
  max-width: 1260px;
  column-gap: 24px;
  grid-template-columns: repeat(12, 1fr);
`

const ContainerFour = styled.div`
  width: 100%;
  grid-column: 1 / 13;
`

const Sidebar = styled.div`
  top: 8px;
  left: 16px;
  width: 200px;
  bottom: 16px;
  display: flex;
  z-index: 1298;
  overflow: visible;
  position: fixed;
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-out;
  border-radius: 8px;
  flex-direction: column;
  background-color: white;
  justify-content: space-around;
`

const SidebarTextContainer = styled.div`
  align-self: center;

  text-orientation: upright;
  writing-mode: vertical-lr;
  text-align: center;
  text-transform: uppercase;
  font-size: 2rem;
  color: lightgray;
`

const SidebarSelect = styled(Select)`
  width: 180px;
  margin: 10px;
  min-width: inherit !important;
`

const TopBar = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  height: 64px;
  z-index: 1372;
  background: linear-gradient(-180deg, rgb(51, 120, 216) 0%, rgb(28, 105, 212) 100%);
  /* background: red; */
  margin: 16px 0px;
  box-sizing: border-box;
  box-shadow: 0px -21px 0px 0px #f8f9fd;
`
const TopBarHolder = styled.div`
  width: 100vw;
  height: 64px;
  z-index: 1372;
`

const Container: React.FC = () => {
  const [selectedConfig, setConfig] = useState(defaultConfig)

  const onChangeConfig = (name: string) => {
    const config = buildConfig(environments[name])

    return setConfig(config)
  }
  return (
    <StyledSAContainer>
      <ContainerTwo>
        <Typography>
          <ConfigurationContext.Provider value={selectedConfig}>
            <Router>
              <Switch>
                <Route path={'/dq'}>
                  <App routingBasePath={'/dq'} credentials={{}} key={selectedConfig.name} selectedHubName={'global'} />
                </Route>
                <Redirect to='/dq'></Redirect>
              </Switch>
            </Router>
          </ConfigurationContext.Provider>
        </Typography>
      </ContainerTwo>
    </StyledSAContainer>
  )
}
const HotApp = hot(Container)

const mountNode = document.getElementById('app')
ReactDOM.render(<HotApp />, mountNode)
