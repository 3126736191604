export const overrideShowExperimentalFeatures = localStorage.getItem('showExperimentalFeatures') === 'true'
export const enableExperiementalFeatures = (flag?: boolean) => {
  if (flag !== false) {
    localStorage.setItem('showExperimentalFeatures', 'true')
  } else {
    localStorage.removeItem('showExperimentalFeatures')
  }
  window.location.reload()
}
Object.assign(window, { enableExperiementalFeatures })
