import * as React from 'react'

export type Props = Pick<React.HTMLAttributes<any>, 'style'> & {
  fullWidth?: true
}

const PageWrapper: React.FC<Props> = ({ children }) => {
  return <>{children}</>
}

export default PageWrapper
