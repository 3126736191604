import axios from 'axios'
import React, { useEffect, useState } from 'react'

interface TokenResponse {
  statusCode: number
  headers: {
    'Set-Cookie': string
  }
  body: {
    name: string
    value: string
  }
}

function generateNonce() {
  const buffer = new Uint8Array(4)
  window.crypto.getRandomValues(buffer)
  return Array.prototype.map.call(buffer, (x) => ('00' + x.toString(16)).slice(-2)).join('')
}

export const authenticateUser = () => {
  return new Promise<boolean>(async (resolve) => {
    const nonce = generateNonce()

    if (document.cookie.startsWith('cdh_prod')) {
      resolve(true)
    } else {
      try {
        const response = await axios.get(
          `https://b34tjlbsuj.execute-api.eu-west-1.amazonaws.com/int/state?code=${nonce}&type=api`
        )
        const state = response.data

        if (response.status === 200) {
          const loginUrl = `https://authentication.iam.data.bmw.cloud/cli/login?state=${state}&idp_name=bmw`
          const popup = window.open(loginUrl, 'login', 'height=800,width=600')

          const checkInterval = setInterval(async () => {
            if (popup?.closed) {
              clearInterval(checkInterval)
              try {
                const response = await axios.get(
                  `https://b34tjlbsuj.execute-api.eu-west-1.amazonaws.com/int/token?state=${state}&code=${nonce}`
                )
                const formatResponse = response.data as TokenResponse
                if (formatResponse.statusCode !== 500) {
                  document.cookie = `${formatResponse.body.name}=${formatResponse.body.value}`
                  resolve(true)
                } else {
                  resolve(false)
                }
              } catch (error) {
                resolve(false)
              }
            }
          }, 50)
        }
      } catch (error) {
        resolve(false)
      }
    }
  })
}
