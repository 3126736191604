import styled from '@emotion/styled'

export const ErrorBoundaryDiv = styled.div`
  max-width: 960px;
  margin-top: 20px;
`
export const DQErrorBoundaryDiv = styled.div`
  max-width: 960px;
  margin-top: 50px;
`
export const ErrorBoundaryInTileDiv = styled.div`
  margin-top: 20px;
  width: 90%;
`
