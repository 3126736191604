import styled from '@emotion/styled'
import { createStyles } from '@material-ui/core/styles'
import { makeStyles, theme } from 'cdh-data-portal-theme'

export default makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    navContainer: {
      padding: `${theme.globalPadding}px 0`,
      display: 'flex',
    },
  })
)

export const DQContainer = styled.div``
