import { MuiThemeProvider, createTheme } from '@material-ui/core'
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles'
import { PickersUtilsProvider, SnackbarProvider, muiTheme } from 'cdh-data-portal-theme'
import { AlertMessage } from 'cdh-data-portal-theme'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useQuery } from 'react-query'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { ReactQueryDevtools } from 'react-query/devtools'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { Redirect, Route, BrowserRouter as Router, Switch, useRouteMatch } from 'react-router-dom'

import { DQContainer } from './DQ.style'
import { DQErrorFallback } from './components/molecules/ErrorBoundaryFallbacks'
import { config } from './config'
import { AppRoute, useRoutes } from './routes'

const minutesInMs = 60 * 1000
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * minutesInMs,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

if (!config.isProduction) {
  const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage })
  persistQueryClient({ queryClient, persistor: localStoragePersistor, buster: 'api2' })
}

const AppRouter: React.FC = () => {
  const routes = useRoutes()
  const routeArray: AppRoute[] = Array.from(Object.values(routes)).sort((a, b) => b.path.length - a.path.length)
  const { path } = useRouteMatch()

  return (
    <>
      {/*
        Add a ?hub=global search parameter for initial open to maintain consistency
        with the Data Portal, which always has one hub (e.g., global) selected.
      */}

      <br />
      <Route path={path + routes.dashboardRoute.path} exact />
      <Switch>
        <Redirect from={path + '//:name'} to={path + '/:name' + '?' + location.search} />
        {routeArray.map((route) => (
          <Route key={route.path} path={path + route.path} children={route.component} />
        ))}
      </Switch>
    </>
  )
}

export const BasePathContext = React.createContext('/')
export const SelectedHubContext = React.createContext('global')

// avoid CSS class name collisions with the hosting component by using a custom prefix
const generateClassName = createGenerateClassName({
  productionPrefix: 'dq',
  seed: 'co',
})

const cdhTheme = createTheme({
  ...muiTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})

const dq: React.FC<{ credentials: any; routingBasePath: string; selectedHubName: string }> = ({
  routingBasePath,
  selectedHubName,
}) => {
  return (
    <ErrorBoundary FallbackComponent={DQErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <PickersUtilsProvider>
          <MuiThemeProvider theme={cdhTheme}>
            <StylesProvider generateClassName={generateClassName}>
              <SnackbarProvider>
                <DQContainer>
                  <Router>
                    <Route path={routingBasePath}>
                      <BasePathContext.Provider value={routingBasePath}>
                        <SelectedHubContext.Provider value={selectedHubName}>
                          <AppRouter />
                        </SelectedHubContext.Provider>
                      </BasePathContext.Provider>
                    </Route>
                  </Router>
                </DQContainer>
              </SnackbarProvider>
              <ReactQueryDevtools />
            </StylesProvider>
          </MuiThemeProvider>
        </PickersUtilsProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

// The remote component must be the default export, otherwise it cannot be loaded with React.lazy
// https://reactjs.org/docs/code-splitting.html#reactlazy
export default dq
