import { Dictionary } from 'lodash'
import React from 'react'

import { overrideShowExperimentalFeatures } from './util/experimentalFeatures'

interface EnvironmentConfiguration {
  name: string
  assetPath: string
  cockpitApiUrl: string
  qualityApiUrl: string
  dataPortalUrl: string
  quicksightApiUrl: string
  graphApiUrl: string
  isProduction?: boolean
  showExperimentalFeatures?: boolean
}

export const environments: Dictionary<EnvironmentConfiguration> = {
  develop: {
    name: 'develop',
    assetPath: 'https://dto-cockpit-dev.aws.bmw.cloud/',
    dataPortalUrl: 'https://portal-master.data.bmw.cloud/',
    cockpitApiUrl: 'https://cockpit-dev.data.bmw.cloud/metadata',
    quicksightApiUrl: 'https://cockpit-dev.data.bmw.cloud/quicksight',
    qualityApiUrl: 'https://meta-dev.data.bmw.cloud/quality/',
    graphApiUrl: 'https://graph-localhost.data.bmw.cloud/',
    showExperimentalFeatures: false,
  },
  developExperimental: {
    name: 'developExperimental',
    assetPath: 'https://dto-cockpit-dev.aws.bmw.cloud/',
    dataPortalUrl: 'https://portal-master.data.bmw.cloud/',
    cockpitApiUrl: 'https://cockpit-dev.data.bmw.cloud/metadata',
    quicksightApiUrl: 'https://cockpit-dev.data.bmw.cloud/quicksight',
    qualityApiUrl: 'https://meta-dev.data.bmw.cloud/quality/',
    graphApiUrl: 'https://graph-localhost.data.bmw.cloud/',
    showExperimentalFeatures: true,
  },
  production: {
    name: 'production',
    assetPath: 'https://dto-cockpit.aws.bmw.cloud/',
    dataPortalUrl: 'https://data.bmw.cloud/',
    cockpitApiUrl: 'https://cockpit.data.bmw.cloud/metadata',
    quicksightApiUrl: 'https://cockpit.data.bmw.cloud/quicksight',
    qualityApiUrl: 'https://meta.data.bmw.cloud/quality/',
    graphApiUrl: 'https://graph.data.bmw.cloud/',
    isProduction: true,
  },
  localhost: {
    name: 'localhost',
    assetPath: 'http://localhost:3001/',
    dataPortalUrl: 'https://portal-master.data.bmw.cloud/',
    cockpitApiUrl: 'http://localhost:8080/',
    quicksightApiUrl: 'https://cockpit-dev.data.bmw.cloud/quicksight',
    qualityApiUrl: 'https://meta-dev.data.bmw.cloud/quality/',
    graphApiUrl: 'https://graph-localhost.data.bmw.cloud/',
    showExperimentalFeatures: false,
  },
  localhostExperimental: {
    name: 'localhostExperimental',
    assetPath: 'http://localhost:3001/',
    dataPortalUrl: 'https://portal-master.data.bmw.cloud/',
    cockpitApiUrl: 'http://localhost:8080/',
    quicksightApiUrl: 'https://cockpit-dev.data.bmw.cloud/quicksight',
    qualityApiUrl: 'https://meta-dev.data.bmw.cloud/quality/',
    graphApiUrl: 'https://graph-localhost.data.bmw.cloud/',
    showExperimentalFeatures: true,
  },
}

const environmentName = process.env.ENVIRONMENT ?? 'develop'
export const activeEnvironment = environments[environmentName]

export const buildFeatureFlags = (environment: EnvironmentConfiguration) => {
  const showExperimentalFeatures = !!environment.showExperimentalFeatures || overrideShowExperimentalFeatures
  return {
    showDataRequirements: showExperimentalFeatures,
    showProductIncrementDetail: showExperimentalFeatures,
    showDataAssetMetadataQualityMetrics: true,
    showUseCaseCost: showExperimentalFeatures,
    showUCCentrallyPaidMetric: showExperimentalFeatures,
    showNewQMs: showExperimentalFeatures,
  }
}

export const buildConfig = (environment: EnvironmentConfiguration) => {
  return {
    ...environment,
    featureFlags: buildFeatureFlags(environment),
    jiraUrl: 'https://atc.bmwgroup.net/jira',
  }
}

export const config = buildConfig(activeEnvironment)
export const ConfigurationContext = React.createContext(config)
export const useConfig = () => React.useContext(ConfigurationContext)
