import axios from 'axios'
import React, { useEffect, useState } from 'react'

import { PageWrapper } from '../../components/atoms/PageWrapper'
import { authenticateUser } from '../../hooks/auth/auth'

export const QuicksightDashboard: React.FC = () => {
  const [URL, setURL] = useState<string>('')
  const [Authenticated, setAuthenticated] = useState<boolean | undefined>(false)
  const [sessionTime, setSessionTime] = useState<Date>()

  interface embedURL {
    embedding_url: string
  }
  let dashboard_id = ''
  if (location.href == 'http://localhost:3000/dq' || location.href == 'https://data-quality.int.aws.bmw.cloud/dq') {
    dashboard_id = 'eba5349b-5b12-4eb3-9730-eff8833c188d'
  } else {
    dashboard_id = '9a49db14-70f1-412d-95f4-37d062366d01'
  }

  console.log(dashboard_id)

  async function getLambdaResponse() {
    try {
      console.log('Generating new URL')

      const response = await axios.post('https://9phhzdi0p5.execute-api.eu-west-1.amazonaws.com/prod/quicksight', {
        dashboard_id: dashboard_id,
      })
      console.log()
      const formatedResponse = response.data as embedURL
      const expirationTime = new Date().getTime() + 60 * 60 * 1000 // 60 minutes from now
      localStorage.setItem('quicksightUrl', JSON.stringify({ url: formatedResponse.embedding_url, expirationTime }))
      setURL(formatedResponse.embedding_url)
      console.log('neue URL:' + formatedResponse.embedding_url)
    } catch (error) {
      console.log('Error:', error)
    }
  }

  // Call the getLambdaResponse method to execute the Lambda function and receive the URL
  useEffect(() => {
    if (Authenticated) {
      const quicksightUrl = localStorage.getItem('quicksightUrl')
      console.log(quicksightUrl)

      if (quicksightUrl) {
        const { url, expirationTime } = JSON.parse(quicksightUrl)
        setSessionTime(expirationTime)

        if (new Date().getTime() > expirationTime) {
          localStorage.removeItem('quicksightUrl')
          getLambdaResponse()
        } else {
          // Split the URL at "embed" and extract the second part
          const embedPart = url.split('embed')[1]
          if (embedPart) {
            // Split the second part at "dashboards" and extract the first part
            const keyword = embedPart.split('dashboards')[0]
            setURL('https://eu-west-1.quicksight.aws.amazon.com/embed' + keyword + 'dashboards/' + dashboard_id)
            console.log(URL)
          }
        }
      } else {
        getLambdaResponse()
      }
    } else {
      authenticateUser().then((result) => {
        setAuthenticated(result)
        console.log(result)
      })
    }
  }, [Authenticated])

  return (
    <PageWrapper>
      <iframe frameBorder='0' width='100%' height='2000em' loading='lazy' src={URL}></iframe>
    </PageWrapper>
  )
}
