import { useConfig } from './config'
import routePaths from './constants/routes'
import { Configurator } from './pages/ExpectationSuiteConfigurator'
import { Quicksight } from './pages/QuicksightDashboard'

export interface AppRoute {
  path: string
  name: string
  component: JSX.Element
  hideFromMenu?: boolean
  isSubPage?: boolean
  navbarIndex?: number
}

const dashboardRoute: AppRoute = {
  path: routePaths.quicksight,
  name: 'Dashboard',
  component: <Quicksight />,
}
// const configuratorRoute: AppRoute = {
//   path: routePaths.configurator,
//   name: 'Configurator',
//   component: <Configurator />,
// }

export const useRoutes = () => {
  const { featureFlags } = useConfig()

  return {
    dashboardRoute,
    // configuratorRoute,
  }
}
